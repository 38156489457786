import styled from '@emotion/styled';
import moment from "moment"
import {useHistory} from "react-router-dom";
import ConversationStatusSwitch from './ConversationStatusSwitch';
import statusEnum from '../enums/statusEnum';
import {getUserSecondaryColor, userHasPermission} from '../Utils/Commons';
import {faClipboard, faFileSignature} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ConversationService from "../services/ConversationService";

const Wrapper = styled.div(
    {
        borderRadius: "3px",
        margin: "5px",
        padding: "10px",
    }, props => (
        {
            fontWeight: props.bold,
            border: "2px solid " + props.color
        }
    )
)

const ClientWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const MessageWrapper = styled.div`
  padding-top: 5px;
  word-break: break-word;
`

const Conversation = ({conversation, setIsCallDeleted}) => {
    const history = useHistory();
    const {t} = useTranslation();
    const [contextMenu, setContextMenu] = useState(null);

    if (conversation.deleted) { return (<div></div>); }

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleDeleteConversation = () => {
        conversation.deleted = true;
        (new ConversationService()).deleteConversation(conversation.id);
        setContextMenu(null);
        setIsCallDeleted(true);
    };

    const handleClick = (conversationId) => {
        if (contextMenu !== null) { return; }
        history.push("/chat/" + conversationId);
    }

    const getLatestStatus = () => {
        return conversation.status?.sort((a, b) => a.id > b.id ? 1 : -1)[conversation.status.length - 1];
    }

    const isLatestStatus = () => {
        return getLatestStatus()?.status === statusEnum.CREATED;
    }

    const getSecondaryColor = () => {
        let color = "";
        if (conversation.isClosedBySystem) {
            color = "orange";
        } else if (conversation.isSupportHebdo && isLatestStatus()) {
            color = "red";
        } else {
            color = getUserSecondaryColor();
        }
        return color;
    }

    const secondaryColor = getSecondaryColor();

    return (
        <Wrapper color={secondaryColor} bold={isLatestStatus() ? "700" : "200"} onClick={() => handleClick(conversation.id)}
                 onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }} >
            <ClientWrapper>
                <div style={{flex: 1}}>
                    {conversation.user?.factory.supportHebdo ?
                        <div style={{fontWeight: 700, color: "red"}}>Support 8-5</div> : ''
                    }
                    <div>{conversation.user?.factory.name}</div>
                    <div>{conversation.user?.factory.specialisationSupport}</div>
                    <div>{moment(conversation.createdAt).format("yy-MM-DD HH:mm")}</div>
                </div>
                <StatusWrapper>
                    <div style={{textAlign: "end"}}>
                        {getLatestStatus().userName !== null ?
                            <div>{getLatestStatus().userName}</div>
                            :
                            <div>{`${conversation.repondentUser?.firstname} ${conversation.repondentUser?.lastname}`}</div>
                        }
                        {conversation.rapportIntervention?.id > 0 &&
                            <FontAwesomeIcon icon={faFileSignature} style={{marginRight: "5px"}}></FontAwesomeIcon>
                        }
                        {conversation.note?.id > 0 &&
                            <FontAwesomeIcon icon={faClipboard} style={{marginRight: "5px"}}></FontAwesomeIcon>
                        }
                        {conversation.isClosedBySystem ? <svg fill="orange" height="16" strokeWidth="1.5" viewBox="0 0 24 24" width="16" className={'svg-inline--fa'}
                                                              xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 22L17 22" stroke="currentColor" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path
                                    d="M2 17V4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17Z"
                                    stroke="currentColor"/>
                                <path d="M9 10.5L11 12.5L15 8.5" stroke="currentColor" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                            :
                            <ConversationStatusSwitch status={conversation.status}/>
                        }
                        <div>{moment(getLatestStatus()?.createdAt).format("yy-MM-DD HH:mm")}</div>
                        {conversation.isClosedBySystem ?
                            <div style={{fontWeight: 'bold'}}>{t('chat.closedBySystem')}</div>
                            :
                            ""
                        }
                    </div>
                </StatusWrapper>
            </ClientWrapper>
            <MessageWrapper>{conversation.messages?.message}</MessageWrapper>
            {userHasPermission("canDeleteConversation") &&
                <Menu
                open={contextMenu !== null}
                onClose={() => {
                    setContextMenu(null)
                }}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                        : undefined
                }
                >
                    <MenuItem onClick={handleDeleteConversation}>Supprimer</MenuItem>
                </Menu>
            }
        </Wrapper>
    )
}

export default Conversation;