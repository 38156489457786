import styled from '@emotion/styled';
import moment from "moment";

const Wrapper = styled.div`
  display: flex;
  margin: 10px 0px;
`

const Bubble = styled.div(
  {
    borderRadius: '3px',
    border: 'none',
    textAlign: 'left',
    padding: '2px 10px 10px',
    maxWidth: '80%',
    wordBreak: 'break-word',
    textSizeAdjust: '100%'
  }, props => ({
    backgroundColor: props.color,
    color: props.bubbleNeedsNotification ? "red" : "black",
    border: props.bubbleNeedsNotification ? "2px solid red" : "2px solid " +  props.color,
    fontWeight: props.bubbleNeedsNotification ? "700" : "400"
  })
)

const BubbleHours = styled.div`
  padding: 0;
  margin: 0;
  font-size: 9px;
  text-align: right;
`

const UserLable = styled.div(
  {
    position: 'absolute',
    color: '#c3c3c3',
    fontSize: '10px'
  }, props => ({
    right: props.isLocal ? '10px' : 'auto'
  })
)

const BubbleWrapper = styled.div(
  {
    paddingBottom: '10px'
  }, props => ({
    textAlign: props.isLocal ? '-webkit-right' : 'left'
  })
)

const ChatBubble = ({ message, userId, secondaryColor, lastMessageSeen, isLast }) => {
    const style = {
        wrapper: {
            justifyContent: 'flex-end'
        },
        remoteBubble: {
            backgroundColor: 'white'
        },
        isTechnician: {
            backgroundColor: '#e6e6e6'
        }
    }
  
    const isLocal = () => {
        return message.userId === userId;
    }

    const bubbleNeedsNotification = () => {
        return !lastMessageSeen && isLast;
    }

    const isTechnician = () => {
        return message.user.factoryId === 1;
    }

    const styleShown = () => {
        if (isTechnician() && !isLocal()) {
            return style.isTechnician;
        } else if (!isLocal()) {
            return style.remoteBubble;
        } else {
            return null;
        }
    }

  return (
    <Wrapper style={ isLocal() ? style.wrapper : null} >
      <BubbleWrapper isLocal={isLocal()}>
        <Bubble color={secondaryColor} bubbleNeedsNotification={bubbleNeedsNotification()} style={styleShown()} >
          <BubbleHours>{moment(message.createdAt).format('HH:mm')}</BubbleHours>
          {message.message}
        </Bubble>
        <UserLable isLocal={isLocal()}>{`${message?.user?.firstname} ${message?.user?.lastname}`}</UserLable>
      </BubbleWrapper>
    </Wrapper>
  )
}

export default ChatBubble;