import styled from '@emotion/styled';
import {faWifi} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

const WrapperSocketStatus = styled.div`
  padding: 5px 25px;
  display: flex;
  height: 10px;
  flex-direction: row;
  justify-content: flex-end;
`

const SocketStatusIndicator = styled.div(
  {
    zIndex: 8
  }, props => ({ color: props.connected ? 'green' : 'red' })
)

const WebSockeStatusIndicator = ({socketStatus}) => {
  return (
      <WrapperSocketStatus>
          <SocketStatusIndicator connected={socketStatus === WebSocket.OPEN}>
              <FontAwesomeIcon icon={faWifi} />
          </SocketStatusIndicator>
      </WrapperSocketStatus>
    )
}

export default WebSockeStatusIndicator;