import styled from "@emotion/styled";
import * as React from "react";
import {useEffect, useState} from "react";
import FactoryService from "../services/FactoryService";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare} from "@fortawesome/free-regular-svg-icons";
import {getUser, getUserColor} from "../Utils/Commons";
import roleType from "../enums/roleType";
import {useTranslation} from "react-i18next";
import SpecialisationType from "../enums/specialisationType";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LogTestConnectionService from "../services/LogTestConnectionService";
import {faBan, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import SupportTimeSlotService from "../services/SupportTimeSlotService";
import Toast from "../components/ui/Toast";

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 5px;
`

const TableWrapper = styled.table(
    {
        borderSpacing: "0 1em",
        minWidth: "40%"
    }, props => (
        {
            marginLeft: props.deviceWidth < 700 ? "60%" : ""
        }
    )
);
const TableContainer = styled.div`
  display: flex;
  justify-content: center;
`

const SelectButton = styled.button`
  height: 26px;
  margin: 0 5px;
`

const TableHead = styled.th`
  border-bottom: 3px solid #4d4d4d;
  padding: 15px;
`

const NotesTextArea = styled.textarea`
  width: 250px;
  margin-left: 15px; 
  height: 50px;
  resize: none;
  overflow-y: auto;
`

const TestConnectionPage = () => {
    const primaryColor = getUserColor();
    const { t } = useTranslation();
    let userSpecSet = false;
    const [factoriesPLC, setFactoriesPLC] = useState([]);
    const [factoriesSCADA, setFactoriesSCADA] = useState([]);
    const [user, setUser] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [updateType, setUpdateType] = useState(null);
    const [selectedFactory, setSelectedFactory] = useState({});
    const [selectedSpecialisation] = useState(() => {
        if (!userSpecSet) {
            userSpecSet = true;
            const user = getUser();
            setUser(user);

            if (user.plcSupport){
                return SpecialisationType.PLC;
            } else if (user.scadaSupport){
                return SpecialisationType.SCADA;
            } else {
                userSpecSet = false;
            }
        }
    });
    const [toastState, setToastState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        message: t('others.testCallToast'),
        severity: 'success',
    });

    const newLog = {
        "id": 0,
        "factoryId": 0,
        "techUserId": 0,
        "logDate": null,
        "note": "",
        "techInitials": "",
        "isTestDeleted": false,
        "dateDeleted": null
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const factoryService = new FactoryService();
        let factoriesData = await factoryService.getFactories();

        const logTestConnectionService = new LogTestConnectionService();
        let logsData = await logTestConnectionService.getAllLogs();

        factoriesData.forEach((factory) => {
            factory.logTest = getLogTestForFactory(logsData.AllLogs, factory.id);
            factory.prevLogTest = getLogTestForFactory(logsData.AllPreviousLogs, factory.id);
        })

        const factoriesDataPLC = factoriesData.filter((factory) => {
            return factory.specialisationSupport === SpecialisationType.PLC && factory.isActive;
        });

        const factoriesDataSCADA = factoriesData.filter((factory) => {
            return factory.specialisationSupport === SpecialisationType.SCADA && factory.isActive;
        });

        setFactoriesPLC(factoriesDataPLC);
        setFactoriesSCADA(factoriesDataSCADA);
    };

    const isDateSet = (factory) => {
        return factory.logTest.logDate !== null;
    };

    const isPrevDateSet = (factory) => {
        return factory.prevLogTest.logDate !== null;
    };

    const handleClicked = (factory, isCompleted) => {
        if(!isDateSet(factory)){
            factory.logTest.logDate = moment();
        }

        factory.logTest.techUserId = getUser().id
        factory.logTest.isTestCompleted = isCompleted;

        const logTestConnectionService = new LogTestConnectionService();
        logTestConnectionService.saveLog(factory.logTest).then(() => fetchData());
    };

    const formatTimezone = (factory, prev = false) => {
        const date = prev ? factory.prevLogTest.logDate : factory.logTest.logDate;
        return isDateSet(factory) || isPrevDateSet(factory) ? moment.utc(date).utcOffset(user.timeZoneOffset).format("YYYY-MM-DD HH:mm:ss") : "";
    };

    const isAdmin = () => {
        return user.role === roleType.ADMIN;
    };

    const handleShowAlert = (factory, updateType) => {
        setShowAlert(true);
        setSelectedFactory(factory);
        setUpdateType(updateType);
    };

    const handleClose = () => {
        setShowAlert(false);
        setSelectedFactory({});
        setUpdateType(null);
    };

    const handleDeleteEntry = async () => {
        const logTestConnectionService = new LogTestConnectionService();
        await logTestConnectionService.deleteLog(selectedFactory.logTest).then(() => fetchData());

        if(updateType !== !!selectedFactory.logTest.isTestCompleted){
            selectedFactory.logTest = {...newLog, factoryId: selectedFactory.id };
            handleClicked(selectedFactory, updateType)
        }

        handleClose();
    };

    const getSpecialisationName = () => {
        if(selectedSpecialisation === SpecialisationType.PLC) return "PLC";
        else if(selectedSpecialisation === SpecialisationType.SCADA) return "SCADA";
    };

    const getLogTestForFactory = (logsData, factoryId) => {
        const log = logsData.find(log => log.factoryId === factoryId && !log.isTestDeleted);
        let newLogCopy = {...newLog, factoryId: factoryId };

        return log ? log : newLogCopy;
    }

    const handleTestCall = async () => {
        setToastState({...toastState, open: true});
        
        const user = getUser();
        const supportTimeSlotService = new SupportTimeSlotService();
        await supportTimeSlotService.makePersonalTestCall(user.id);
    };

    const AlertDialog = () => {
        return (
            <div>
                <Toast toastState={toastState} setToastState={setToastState} />
                <Dialog
                    open={showAlert}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {t('alertDialog.title')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t('alertDialog.content').replace("%factory%", selectedFactory.name)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteEntry}>{t('alertDialog.accept')}</Button>
                        <Button onClick={handleClose}>{t('alertDialog.cancel')}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    const TestConnexionComponent = (props) => {
        const [factory, setFactory] = useState(props.factory);
        const setFactoryData = props.updateFactoryData;

        const handleOnChangeNote = (value) => {
            const factoryData = {...factory};
            factoryData.logTest.note = value;
            setFactory(factoryData)
        };

        const handleOnBlur = () => {
            const factories = [...props.factories];
            factories[props.index] = factory;
            setFactoryData(factories);

            if (isDateSet(factory)){
                handleClicked(factory, factory.logTest.isTestCompleted);
            }
        };

        return (
            <tr key={ factory.id } style={{marginBottom: "10px"}}>
                <td>{ factory.name }</td>
                <td style={{textAlign: "center"}}>
                    <SelectButton style={isDateSet(factory) && factory.logTest.isTestCompleted ? {backgroundColor: "green"} : {}}
                                  onClick={() => {
                                      isDateSet(factory) ? handleShowAlert(factory, true) : handleClicked(factory, true)
                                  }}>
                        <FontAwesomeIcon icon={faCheckSquare}></FontAwesomeIcon>
                    </SelectButton>
                    <SelectButton style={isDateSet(factory) && !factory.logTest.isTestCompleted ? {backgroundColor: "red"} : {}}
                                  onClick={() => {
                                      isDateSet(factory) ? handleShowAlert(factory, false) : handleClicked(factory, false)
                                  }}>
                        <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
                    </SelectButton>
                </td>
                <td style={{textAlign: "center"}}>
                    {!isDateSet(factory) ?
                        <div style={{color: "#a89e9e"}}>{factory.prevLogTest.techInitials}</div>
                        :
                        <div>{factory.logTest.techInitials}</div>
                    }
                </td>
                <td style={{textAlign: "center"}}>
                    {!isDateSet(factory) ?
                        <div style={{color: "#a89e9e"}}>{ formatTimezone(factory, true) }</div>
                        :
                        <div>{ formatTimezone(factory) }</div>
                    }
                </td>
                <td style={{textAlign: "center"}}>
                    {!isDateSet(factory) ?
                        <NotesTextArea defaultValue={factory?.prevLogTest.note} disabled={true} />
                        :
                        <NotesTextArea value={factory?.logTest.note} onChange={(event) => handleOnChangeNote(event.target.value)}
                                  onBlur={() => handleOnBlur()} maxLength={250} />
                    }
                </td>
            </tr>
        );
    }

    return (
        <>
            <AlertDialog></AlertDialog>
            <Wrapper>
                <div style={{maxWidth: "40%", textAlign: "center", marginLeft: "30%"}}>
                    <div>
                        {t('others.personalCallSimulation')}:
                        <IconButton onClick={() => handleTestCall()} primaryColor={primaryColor}>
                            <FontAwesomeIcon icon={faPhoneAlt} size="sm" color={primaryColor} />
                        </IconButton>
                    </div>
                    <hr style={{border: "1px solid black" }} />
                </div>
                <h3 style={{textAlign: "center"}}>{t('others.testConnectionTitle')}</h3>
                <TableContainer>
                    <TableWrapper deviceWidth={window.innerWidth}>
                        <thead>
                            <tr style={{borderBottom: "3px solid #4d4d4d"}}>
                                <TableHead style={{textAlign: "left"}}>{t('others.factory')} - PLC</TableHead>
                                <TableHead>{t('others.completed')}</TableHead>
                                <TableHead>{t('others.initials')}</TableHead>
                                <TableHead>Date</TableHead>
                                <TableHead>{t('others.notes')}</TableHead>
                            </tr>
                        </thead>
                        <tbody>
                            {factoriesPLC?.map((factory, i) => (
                                <TestConnexionComponent index={i} factory={factory} factories={factoriesPLC} updateFactoryData={setFactoriesPLC} key={i} />
                            ))}

                            <tr>
                                <TableHead style={{textAlign: "left"}}>{t('others.factory')} - SCADA</TableHead>
                                <TableHead></TableHead>
                                <TableHead></TableHead>
                                <TableHead></TableHead>
                                <TableHead></TableHead>
                            </tr>

                            {factoriesSCADA?.map((factory, i) => (
                                <TestConnexionComponent index={i} factory={factory} factories={factoriesSCADA} updateFactoryData={setFactoriesSCADA} key={i} />
                            ))}
                        </tbody>
                    </TableWrapper>
                </TableContainer>
            </Wrapper>
        </>
    )
};

export default TestConnectionPage;